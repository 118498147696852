import React, { useState } from "react";
import axios from "axios";

const ESP_AxiosButton = ({ text, route, callback, method }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClick = () => {
    if (isLoading) return;
    setIsLoading(true);
    setIsError(false);
    var config = {
      method: method,
      url: `http://esp8266.local${route}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        let message = JSON.stringify(response.data);
        setIsLoading(false);
        callback(message);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  };
  return (
    <button className={isError ? "error" : ""} onClick={handleClick}>
      {isError ? `Error: ${text}` : isLoading ? "..." : text}
    </button>
  );
};

export default ESP_AxiosButton;
