import React, { useEffect, useState } from "react";

const SuccessMessage = ({ message, setMessage }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setIsVisible(false);
      setMessage(null);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (!isVisible) return null;
  return <p className="successMsg">{message}</p>;
};

export default SuccessMessage;
