import React, { useState } from "react";
import Header from "./Header";
import Container from "./Container";
import Row from "./Row";
import ESP_AxiosButton from "./ESP_AxiosButton";
import { v4 as uuid } from "uuid";
import SuccessMessage from "./SuccessMessage";
import ESP_Form from "./ESP_Form";

const ButtonGroup = ({ data }) => {
  const [message, setMessage] = useState(null);

  return (
    <Container>
      <Header text={data.header || "???"} />

      <Row>
        {data?.buttons?.map((button, i) => (
          <ESP_AxiosButton key={uuid()} text={button.text} method={button.method} route={button.route} callback={(e) => setMessage(e)} />
        ))}
      </Row>

      <Row>
        {data?.forms?.map((form, i) => (
          <ESP_Form key={uuid()} text={form.text} method={form.method} route={form.route} inputs={form.inputs} callback={(e) => setMessage(e)} />
        ))}
      </Row>
      {message && <SuccessMessage message={message} setMessage={setMessage} />}
    </Container>
  );
};

export default ButtonGroup;
