import React, { useState } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";

const ESP_Form = ({ text, method, route, callback, inputs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    setIsError(false);
    var config = {
      method: method,
      url: `http://esp8266.local${route}?${new URLSearchParams(new FormData(e.target)).toString()}`,
      headers: {},
    };

    console.log(config);

    axios(config)
      .then(function (response) {
        let message = JSON.stringify(response.data);
        setIsLoading(false);
        callback(message);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  };

  return (
    <form key={uuid()} onSubmit={handleSubmit}>
      <h4>{text}</h4>
      {inputs.map((input, i) => (
        <label key={uuid()}>
          {input.label}
          <input type={input.type} name={input.name} min={input.min} max={input.max} />
        </label>
      ))}
      <input type="submit" />
    </form>
  );
};

export default ESP_Form;
