import "./App.css";
import ButtonGroup from "./Components/ButtonGroup";
import { v4 as uuid } from "uuid";

const h1 = "Liam's Smart Home";
const buttonGroups = [
  // {
  //   header: "Onboard LED",
  //   buttons: [{ text: "Toggle Light", route: "/light/toggle", method: "post" }],
  // },
  {
    header: "Chicken Coop Door",
    buttons: [
      { text: "Run Auto", route: "/door/auto", method: "post" },
      { text: "Open", route: "/door/open", method: "post" },
      { text: "Close", route: "/door/close", method: "post" },
      { text: "Get State", route: "/door", method: "get" },
    ],
    forms: [
      {
        text: "Set Times",
        route: "/door/times",
        method: "post",
        inputs: [
          { name: "morning", type: "number", label: "Open (am)", min: 0, max: 12 },
          { name: "night", type: "number", label: "Close (pm)", min: 0, max: 11 },
        ],
      },
    ],
  },
];

function App() {
  return (
    <>
      <h1>{h1}</h1>
      {buttonGroups.map((el) => (
        <ButtonGroup data={el} key={uuid()} />
      ))}
    </>
  );
}

export default App;
